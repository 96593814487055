.hurye {
  background-color: rgb(0, 0, 0) !important;
  height: 300px;
  margin-top: 100px !important;
  background-image: url('../../pages/images/chance.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.exp {
  color: #fff;
  text-align: center;
  font-family: Bebas Neue;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 109.375% */
}
.exppd {
  background-color: black;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px !important;
}
.ynd {
  color: var(--white, #fff);
  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 50%;
  margin-top: 30px;
}
.all {
  width: 200px;
  height: 54px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  outline: none;
  border: none;
  background: #333333;
  border-radius: 10px;
}
.res {
  background-color: rgb(0, 0, 0);
  padding-top: 200px;
}
.uxx {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
}

.fakse {
  background-image: url('../images/dearr.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  height: 360px;
}
.fakse2 {
  background-image: url('../images/nike.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  height: 360px;
}
.dem {
  background: linear-gradient(
    180deg,
    rgba(55, 55, 55, 0) 46.43%,
    #2f2f2f 98.33%
  );
  height: 360px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.wsisisi {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}
.linearr {
  width: 225px;
  height: 360px;
  background-image: url('../images/9.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12.4732px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 24px;
}
.call-me-out {
  padding-bottom: 40px;
}
.view {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  outline: none;
  border: none;
  color: #ffffff;
  width: 158px;
  height: 47px;
  background: #333333;
  border-radius: 10px;
}
.linkee {
  background-color: black;
  /* margin-top: -200px; */
  padding-top: 60px;
}
.desny {
  font-family: Outfit;
  font-style: normal;
  font-weight: 800;
  font-size: 58px;
  color: #ffffff;
}
.x-bill {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.textp {
  margin-top: 30px;
  font-family: Outfit;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  height: 34px;
  cursor: pointer;
  margin-right: 20px;
  /* border-bottom: 5px solid #DE1C0F; */
}
.selectedddd {
  border-bottom: 5px solid #de1c0f !important;
}
.allmfc {
  background-color: black;
  padding-top: 120px;
}
.anita-gift {
  font-family: 'Paytone One';
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  text-align: center;
  color: #fdefde;
}
.ux-oo {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14.4px;
  text-align: center;
  margin-bottom: 15px;
  color: #fdefde;
}
.durat {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12.6px;
  text-align: center;
  color: #ffffff;
}
.actss {
  border-bottom: 0.1px solid #ffffff;
}
.cpcp {
  background-color: black;
  padding-top: 30px;
  padding-bottom: 70px;
  border-bottom: 0.1px solid #ffffff;
}
.corner {
  background-image: url('../images/tryout.webp');
  background-repeat: no-repeat;
  background-size: cover;
  /* min-height: 550px; */
  height: 100%;
  border-radius: 9px;

  margin-top: 70px;
  padding: 40px;
}
.namee {
  color: var(--white, #fff);
  font-family: Bebas Neue;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tittle {
  color: var(--white, #fff);
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.timee {
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
}
.priced {
  color: var(--white, #fff);
  font-family: Outfit;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
}
.buy-now {
  color: var(--white, #fff);
  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 25.6px */
  width: 158.25px;
  height: 50px;
  outline: none;
  border: none;
  background: #de1c0f;
  border-radius: 10px;
  margin-right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.watch {
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  width: 213.65px;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-color: #2f2f2f00;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.playna {
  color: #808080;
  font-size: 27px;
  margin-right: 8px;
}
.coursed {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #ffffff;
}
.datys {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  cursor: not-allowed;
}
.por-que {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.5;
  width: 80%;
}
.vibess {
  background: #100c0c;
  border-radius: 10px;
  padding-bottom: 22px;
}
.temper {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  align-items: center;
  width: 100%;
}
.nana {
  margin-right: 9px;
  color: white;
  font-size: 22px;
}
.wines {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.boyo {
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #ffffff;
}
.hmmm {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  color: white !important;
  box-shadow: none !important;
}
.ouya {
  background-color: #000000 !important;
  color: white;
  opacity: 0.5;
  border-radius: 10px;
  /* margin-bottom: 3px; */
}
.introvid {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.shaws {
  padding-top: 120px;
  background: #26272d;
  width: 100%;
  padding-bottom: 180px;
}
.dearly_try {
  margin-top: 150px;
}
.wywl {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.reqq {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.5;
}
.kissone {
  width: 100%;
  height: 400px;
  display: flex;
  /* background-color: green; */
  justify-content: center;
}
.kissonee {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* background-color: blue; */
  width: 50%;
}
.backseat {
  display: flex;
  justify-content: flex-end;
  /* background-color: red; */
  margin-bottom: 19px;
  cursor: pointer;
  font-size: 25px;
  color: white;
}
.fitzz {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course_title {
  color: var(--white, #fff);
  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.all_green {
  margin-top: 80px;
}
.see_more {
  display: flex;
  padding: 7px 18px;
  border-radius: 5px;
  background: var(--text-colour, #333);
  justify-content: center;
  align-items: center;
  gap: 9.649px;
  color: var(--white, #fff);
  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
  outline: none;
  border: none;
}
.four_all {
  margin-top: 70px;
}
.long_road {
  margin-top: 85px;
}
.col_image_ho {
  display: flex;
  justify-content: center;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
}
.again {
  margin-top: 70px;
}
@media screen and (max-width: 991px) {
  .corner {
    background-image: url('../images/tryout.webp');
    background-repeat: no-repeat;
    background-size: cover;
    /* min-height: 550px; */
    height: 100%;
    border-radius: 9px;

    margin-top: 70px;
    padding: 20px;
  }
  .ynd {
    color: var(--white, #fff);
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    margin-top: 30px;
  }
  .por-que {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    opacity: 0.5;
    text-align: center;
    width: 100% !important;
  }
  .uxx {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    color: #ffffff;
    text-align: center !important;
    margin-top: 80px;
  }
}
.oops-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 90px;
  margin-top: -40px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
  text-align: center !important;
}
.oops-message2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 90px;
  margin-top: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
  text-align: center !important;
}
.bvhvbh {
  height: 200px;
  width: 200px;
  background-color: white;
  border-radius: 55% 45% 45% 55% / 65% 45% 55% 35%;
}
@media screen and (min-width: 1440px) {
  .hurye {
    background-color: rgb(0, 0, 0) !important;
    height: 520px;
    margin-top: 100px !important;
    background-image: url('../../pages/images/chance.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
}
