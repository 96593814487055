@media (max-width: 991px) {
  .ayh22 {
    position: fixed;
    top: 77px;
    left: 0;
    transform: translateX(-100%);
    height: 100%;
    background-color: rgb(0, 0, 0);
    transition: transform 0.3s ease-in-out;
  }

  .ayh22.show {
    transform: translateX(0%);
  }

  .bmbmbmb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.bubuf {
  background: #ffffff;
  margin-right: -30px !important;
  outline: none;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bmbmbmb {
  /* background-color: red; */
  width: 100%;
}
.nity22y {
  /* background-color: green; */
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}
.CoursesCourses {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-left: 10px;
  color: #ffffff;
}
.miltme {
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 992px) {
  .bubuf {
    display: none;
  }
  .miltme {
    /* background-color: red; */
    display: none !important;
  }
}
.nvfhd {
  /* background-color: red; */
  width: 100%;
}
.produces-result {
  background-color: black !important;
  color: white !important;
  /* width: 100vw !important; */
}
.barrien {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  background-color: #191919 !important;
  /* border-radius: 9px !important; */
  /* margin-top: 20px; */
}
.crty {
  /* background-color: rgb(0, 255, 4); */
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.influence {
  margin-right: 13px;
  font-size: 22px;
  color: #ffffff;
  opacity: 0.5;
}

.intto {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}
.helloe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  cursor: pointer;
}
.lady {
  font-size: 20px !important;
  color: #ffffff !important;
}
.watched {
  color: var(--dark-grey, #737373);
}
