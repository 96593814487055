.hello {
  /* background-color: red; */
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.better {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dentos {
  background: rgba(255, 255, 255, 0.065);

  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
}
.emb {
  color: var(--white, #fff);
  text-align: center;

  /* body-text 2 */
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.emb33 {
  margin-left: 60px;

  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .steez {
    margin-left: -150px !important;
  }
  .better {
    display: block !important;
  }
  .emb {
    margin-left: 0px !important;
    font-family: Outfit;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 13px;
    margin-top: 13px;
    cursor: pointer;
  }
  .emb33 {
    margin-left: 0px !important;
    font-family: Outfit;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 13px;
    margin-top: 13px;
    cursor: pointer;
  }
  .niboo {
    display: flex;
    padding: 10px 22px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    margin-left: 0px !important;
    background: #191919;
  }
  .niboo22 {
    display: flex;
    padding: 11px 36px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    margin-left: 0px !important;
    background: var(--red, #de1c0f);
    color: var(--white, #ffffff);
  }
}
.red {
  color: #de1c0f;
}
.circle {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgb(209, 207, 207);
  object-fit: cover;
}
.swing {
  /* background-color: red; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90px;
}
.lanee {
  background-color: red;
}

.relax {
  color: #000000;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 8px;
  padding-bottom: 8px;
}
.niboo {
  display: flex;
  padding: 11px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  margin-left: 60px;
  background: #191919;
  color: var(--dark-grey, #737373);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  width: fit-content;
}
.niboo22 {
  display: flex;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  margin-left: 60px;
  background: var(--red, #de1c0f);
  color: var(--white, #ffffff);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  width: fit-content;
}
/* @media screen and (min-width: 992px) {
  .swingg {
    display: none;
  }
} */
