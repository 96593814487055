.shy {
  background-color: black;
  padding-top: 109px;
  padding-bottom: 30px;
}
.round {
  display: flex;
  align-items: center;
}
.fav {
  width: 50px;
  height: 50px;
  background-color: rgb(232, 232, 232);
  border-radius: 50%;
  margin-right: 30px;
  object-fit: cover;
}
.welcom {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #ffffff;
}
.edit {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.d-shoes {
  height: 60px;
  width: 500px;
  background: #191919;
  border-radius: 5px;
  color: white;
  outline: none;
  border: none;
  padding-left: 20px;
}

@media screen and (max-width: 991px) {
  .compoiu {
    display: none !important;
  }
  .civil {
    width: 100% !important;
    height: 150px;
    background: #191919;
    border-radius: 10px;
  }
  .d-shoes {
    height: 60px;
    width: 100% !important;
    background: #191919;
    border-radius: 5px;
    color: white;
    outline: none;
    border: none;
  }
}
.literally {
  display: flex;
  align-items: center;
}
.nows {
  background-color: #191919;
  height: 45px;
  width: 38px;
  display: flex;
  align-items: center;
  margin-left: -40px;
  cursor: pointer;
  color: white;
  font-size: 24px;
}
.menn {
  margin-top: 70px;
}
.badgess {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 35px;
}

.civil {
  width: 450px;
  height: 150px;
  background: #191919;
  border-radius: 10px;
  margin-bottom: 35px !important;
  padding: 30px;
}
.despirate {
  display: flex;
  justify-content: space-between;
}
.coursesd {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
}
.numbers {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  margin-top: 4px;
  color: #ffffff;
}
.awards {
  font-size: 80px;
  color: #8c8c8c;
}
.go-back {
  margin-top: 45px;
}
.my-course {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;

  color: #ffffff;
}

.faksepp {
  background-image: url('../images/dearr.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px 12px 0 0;
  height: 360px;
}

.dem78 {
  background: linear-gradient(
    180deg,
    rgba(55, 55, 55, 0) 46.43%,
    #2f2f2f 98.33%
  );
  height: 360px;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.call-me-out {
  padding-bottom: 40px;
}

.anita-gift {
  font-family: 'Paytone One';
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  text-align: center;
  color: #fdefde;
}
.ux-oo {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14.4px;
  text-align: center;
  margin-bottom: 15px;
  color: #fdefde;
}
.durat {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12.6px;
  text-align: center;
  color: #ffffff;
}
.continue {
  background: #191919;
  border-radius: 0 0 12px 12px;
  height: 50px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.desde {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  color: #ffffff;
}
.vincentur {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #fdefde;
}
.for-vidd {
  margin-top: 20px;
  object-fit: cover;
  width: 100%;
}
.loinuhy {
  margin-top: 49px;
  padding-top: 40px;
  padding-bottom: 20px;
  background: #191919;
}
.cdcd {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #ffffff;
}
.ayh22 {
  width: 280px !important;
  z-index: 1;
  /* padding-bottom: 20px; */
}
.njuyh {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 20px;
}
.about {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  margin-top: 22px;
  color: #ffffff;
}
.rgnfd {
  display: flex;
  /* background-color:red ; */
  justify-content: center;
}

.whatsde {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;

  /* display: flex;
justify-content: center;
align-items: center; */
}
.flott {
  border-bottom: 2.5px solid #ff4703 !important;
  height: 40px;
  cursor: pointer;
  width: fit-content;
  text-align: center;
}
.flottee {
  /* border-bottom: 2.5px solid #FF4703 !important; */
  height: 40px;
  cursor: pointer;
  width: fit-content;
  text-align: center;
}
.my-own {
  /* margin-top: 40px; */
  width: 100%;
}
.wiuy {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
  color: #ffffff;

  opacity: 0.5;
}
.hiderr {
  display: none;
}
.hiderr2 {
  display: none;
}
.yhyhyh {
  margin-top: 90px;
}
.celeb {
  display: flex;
  /* background-color: red; */
  margin-top: 20px;
  justify-content: space-between;
}
.azul {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.wsisisi2 {
  /* background-color: red; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}
.linearr2 {
  width: 225px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12.4732px 12.4732px 0 0 !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* margin-right: 24px; */
}
.heart_flow {
  width: 225px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 12.4732px 12.4732px !important;
  align-items: center;
  background: #191919;
  height: 50px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: flex;
}
.change22 {
  padding-bottom: 50px;
  /* border-radius: 12.4732px; */
  width: 225px;
  height: 300px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(55, 55, 55, 0) 46.43%,
    #2f2f2f 98.33%
  );
}
@media screen and (min-width: 992px) {
  .vnvnvnf {
    display: none !important;
  }
}
.whooo {
  display: flex;
  /* background-color: blue; */
  align-items: center;
}
.tutor_image {
  background-color: #191919;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.extara {
  color: var(--white, #fff);
  font-family: Manrope;
  font-size: 14.988px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}
.description {
  opacity: 0.5;
  color: var(--white, #fff);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-top: 30px;
  /* background-color: red; */
  width: 100%;
}
