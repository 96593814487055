.fix-that {
  background-image: url('../images/new-ux.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 800px;
  margin-top: 50px;
}

.class {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.learn {
  color: #fff;
  font-family: 'Clash Display', sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 600;
  line-height: 131.135px;
  width: 767px;
}
.starts {
  color: var(--white, #fff);
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 460px;
  margin-top: 10px;
}
.Browse {
  width: 225px;
  height: 55px;
  background: #de1c0f;
  border-radius: 10px;
  outline: none;
  border: none;
  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: #ffffff;
}
.anoy {
  margin-top: 25px;
}
.woit {
  background-color: rgb(0, 0, 0);
  /* padding-top: 55px; */
  padding-bottom: 55px;
}
.trending {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
}

.owns {
  background-color: #26272d;
  background-image: url('../images/tryout.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}
.leftTrending {
  padding-top: 80px;
  /* padding-left: 80px; */
  padding-bottom: 40px;
}
.imageside {
  object-fit: cover;
  display: none;
}
.tennnn {
  color: var(--white, #fff);

  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: start;
}
.control-dots {
  display: none !important;
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
  display: none !important;
}
.desss {
  color: var(--white, #fff);
  font-family: Bebas Neue;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  text-align: start;
}
.texttxx {
  color: var(--white, #fff);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  margin-top: 20px;
  text-align: start;
}
.prihfuu {
  color: var(--white, #fff);
  font-family: Outfit;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
  margin-top: 20px;
  text-align: start;
}
.dt {
  /* width: 330px; */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  color: #ffffff;
  text-align: start !important;
}
.forget {
  /* width: 390px; */
  margin-top: 30px;
}
.from {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 20px;
  text-align: start !important;
}
.buttonLinkk {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
.enroll {
  color: var(--white, #fff);
  text-align: center;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 25.6px */
  width: 158.25px;
  height: 60px;
  background: #de1c0f;
  outline: none;
  border: none;
  border-radius: 10px;
}
.woeko {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  color: #ffffff;
  margin-left: 16px;
}
.platme {
  background-color: rgb(0, 0, 0);
  padding-top: 25px;
  padding-bottom: 55px;
}
.ava {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
}
.linear {
  width: 225px;
  height: 360px;
  background-image: url('../images/9.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12.4732px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 24px;
}
.flexxxx {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
}
.leftright {
  display: flex;
}
.putyty {
  /* margin-left: 80px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-up {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.flex-up div {
  margin-right: 24px;
  cursor: pointer;
}
.flex-up::-webkit-scrollbar {
  background: transparent;
  /* make scrollbar transparent */
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
.flex-up li {
  background: #ff61f6;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  margin: 0 4px;
  white-space: nowrap;
}
.change {
  padding-bottom: 50px;
  border-radius: 12.4732px;
  width: 225px;
  height: 360px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(55, 55, 55, 0) 46.43%,
    #2f2f2f 98.33%
  );
}
.anita {
  font-family: 'Paytone One';
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  text-align: center;
  color: #fdefde;
}
.ux {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14.4px;
  margin-top: 8px;
  text-align: center;
  color: #fdefde;
}
.hour {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12.6px;
  text-align: center;
  margin-top: 15px;
  color: #ffffff;
}

.next {
  /* background-color: red; */
  height: 40px;
  width: 40px;
  border-radius: 55px;
  cursor: pointer;
  background-color: #191919;
  padding: 15px;
  margin-right: -40px;
  margin-left: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: white;
}
.next2 {
  /* background-color: red; */
  height: 40px;
  width: 40px;
  border-radius: 55px;
  cursor: pointer;
  background-color: #191919;
  padding: 15px;
  margin-right: -50px;
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: white;
}

.man {
  height: 100%;
  z-index: 100;
}
.gogo {
  background: linear-gradient(
    86.95deg,
    #000000 14.16%,
    rgba(3, 3, 3, 0) 84.29%
  );

  height: 360px;
  width: 105px;
  margin-right: -80px;
  display: flex;

  align-items: center;
}
.gogo2 {
  background: linear-gradient(
    266.95deg,
    #000000 14.16%,
    rgba(3, 3, 3, 0) 84.29%
  );
  height: 360px;
  width: 105px;
  display: flex;
  justify-content: center;
  margin-left: -80px;
  align-items: center;
}
.view {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  width: 158.25px;
  height: 60px;
  background: #333333;
  border-radius: 10px;
}
.going {
  background-color: rgb(0, 0, 0);
  padding-top: 75px;
  padding-bottom: 55px;
}
.tiemsd {
  min-width: 500px;
  min-height: 250px;
  background-image: url('../images/image.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12.473px;
  padding: 20px;
  margin-right: 30px;
  margin-top: 30px;
}
.cs {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  width: 80px;
  height: 20px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
}
.push-down {
  margin-top: 55px;
}
.cname {
  font-family: 'Paytone One';
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  color: #fdefde;
}
.ctitle {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14.4px;
  color: #fdefde;
}
.designed {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  color: #ffffff;
}
.Access {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
}
.hor {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  width: 275px;
  opacity: 0.5;
}
.four {
  margin-top: 120px;
}

.aok {
  margin-top: 125px;
  display: flex;
  justify-content: center;
}
.vide {
  background-image: url('../images/vid.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 620px;
  width: 100%;
}
.trew {
  display: flex;
  /* justify-content: center; */
  margin-top: 130px;
  flex-direction: column;
  align-items: center;
}
.watch {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}
.watch22 {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}
.zone {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 25px;
}
.brow {
  font-family: 'Lato';
  margin-top: 19px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  width: 243px;
  height: 55px;
  outline: none;
  background: #de1c0f;
  border-radius: 5px;
  border: none;
  color: #ffffff;
}
.wiloo {
  width: 100%;
}
.exxxp {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  text-transform: capitalize;
  margin-top: 50px;

  color: #ffffff;
}
.backdu {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191919;
  border-radius: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;

  color: #ffffff;
}
.backdu:hover {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white, #fff);
  border-radius: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;

  color: #333;
}
.wressleball {
  margin-top: 60px;
}
.test_holders {
  padding-top: 150px;
  padding-bottom: 120px;
}
.pen {
  margin-right: 17px;
}
.pass-na {
  padding-top: 140px;
  background-color: black;
}
.backfi {
  background: #101010;
  /* height: 200px; */
  padding-bottom: 30px;
}
.pasr {
  padding-top: 120px;
}
.ght {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
}
.in-my-mind {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 30px;
}
.fn {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.puhh {
  background: #191919;
  border-radius: 4.0585px;
  outline: none;
  border: none;
  height: 53.56px;
  width: 100%;
  padding-left: 30px;
  color: white;
}
.sub {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #de1c0f;
  border-radius: 5px;
  width: 149px;
  height: 47px;
  outline: none;
  border: none;
}
.eleke {
  display: flex;
  align-items: center;
}
.listen {
  background-image: url('../images/bnb.png');
  background-repeat: repeat;
  background-size: cover;
  height: 562px;
}
.oh-no {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.l-up {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
}
.we-talk {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
}
.rest {
  width: 70%;
}
.spotify {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 284px;
  height: 55px;
  outline: none;
  border: none;
  background: #00ab4f;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sp {
  margin-left: 15px;
}
/* .tablee{
  background: linear-gradient(90.27deg, #000000 0.85%, rgba(2, 2, 2, 0.927585) 34.97%, rgba(25, 25, 25, 0) 66.82%);
} */

.selected {
  border-bottom: 5px solid #ff460300 !important;
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #ffffff00 !important;
}
.carousel.carousel-slider .control-arrow {
  display: none !important;
}
.centt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  height: 100vh;
}
.loof {
  width: 50%;
}
.bebeb {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 10px;
}
.podd {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;

  color: #ffffff;
  margin-top: 30px;
}
.podd2 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  width: 300px;
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
  outline: none;
  background-color: #00ab4f;
  border: none;
  padding: 17px;
  border-radius: 10px;
}
.coldd {
  text-decoration: underline;
  color: white;
}
.backkk {
  background-image: url('../images/background.png');
  background-position: center;
  background-size: cover;

  height: 100vh;
  width: 100%;
}
.eboor {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Testimonials {
  color: var(--white, #fff);
  text-align: center;

  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.boxesd {
  width: 35%;
  height: 100%;
  /* background-color: red; */
  padding: 25px;
  flex-shrink: 0;
  margin-top: 140px;
  border-radius: 10px;
  border: 0.6px solid #626262bf;
}
.d_flex {
  display: flex;
  overflow: scroll;
  gap: 30px;

  width: 100%;
}
.d_flex::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.d_flex {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.image_rectangle {
  width: 100%;
  height: 300px;
  border-radius: 21.325px;
  /* background: #ff4703; */
  object-fit: cover;
}
.what_to_say {
  color: #fff;
  width: 100%;
  /* margin-top: 50px; */

  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
.ballz {
  display: flex;
  align-items: center;
  margin-top: 50px;
}
.image_ave {
  width: 48px;
  height: 48px;
  border-radius: 50%;

  margin-right: 10px;
  object-fit: contain;
}
.namexz {
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
}
.rolex {
  color: #fff;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}
@media screen and (max-width: 991px) {
  .d_flex {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 25px;
    width: 100%;
  }
  .boxesd {
    width: 100%;
    height: 100%;
    padding: 18px;
    /* background-color: red; */
    flex-shrink: 0;
    margin-top: 40px;
  }
  .backdu {
    width: 100% !important;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #191919;
    border-radius: 10px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
    color: #ffffff;
  }
  .putyty {
    /* margin-left: 14px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .learn {
    color: #fff;
    font-family: Clash Display;
    font-size: 58px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 90%;
  }
  .starts {
    color: var(--white, #fff);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 90%;
    margin-top: 10px;
  }
  .fix-that {
    position: relative;
    background-image: url('../images/new-ux-mobile.webp');
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    height: 600px;
    margin-top: 90px;
  }

  .fix-that::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      0,
      0,
      0,
      0.6
    ); /* Adjust the opacity (last value) for dimming effect */
    z-index: 1;
  }
  .weeeoe {
    position: relative; /* Ensure that the container has a stacking context */
    z-index: 1; /* Set a higher z-index than the ::before pseudo-element */
  }
  .leftTrending {
    padding-top: 70px;
    padding-left: 20px;
  }
  .owns {
    background-color: #26272d;
    background-image: none !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }
  .imageside {
    width: 80%;
    border-radius: 18px;

    height: 187px;
    flex-shrink: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .conforrr {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 1440px) {
  .what_to_say {
    color: #fff;
    width: 70%;
    margin-top: 50px;

    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
  }
}
