.feature {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  margin-top: 35px;
}
.favss {
  height: 150px;
  background: #191919;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.spanit {
  display: flex;
  justify-content: space-between;
}
.uploada {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
}
.cloudss {
  font-size: 45px;
  color: #505050;
}
.Trending {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: #ffffff;
  margin-top: 15px;
}
.uxDesign {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  text-align: center;
  color: #ffffff;
}
.centerdd {
  text-align: center;
}
.cloudesx {
  display: flex;
  justify-content: center;
  z-index: 1;
  font-size: 45px;
  color: #505050;
}
.light-up {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  width: fit-content;
}
.whiteemm {
  color: #ffffff;
}

.dark-again {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
  width: fit-content;
}
.feeelz {
  background: #191919;
  border-radius: 5px;
  outline: none;
  width: 90%;
  height: 60px;
  border: none;
  color: white;
  padding-left: 20px;
}
.feeelz3 {
  background: #000000;
  border-radius: 5px;
  outline: none;
  width: 320px;
  height: 60px;
  border: none;
  color: white !important;
  padding-left: 20px;
}
.feeelz2 {
  background: #191919;
  border-radius: 5px;
  outline: none;
  width: 95%;
  height: 210px;
  border: none;
  color: white;
  padding: 30px;
}
.cacus {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 22px;
  margin-top: 22px;
}
.forImahe {
  /* height: 250px; */
  width: 95%;
  background: #191919;
  border-radius: 5px;
  padding: 9px;
}
.broken-line {
  border: 1px dashed #737373;
  border-radius: 5px;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
}
.click {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
.upload-Button {
  border: none;
  outline: none;
  width: 110px;
  height: 50px;
  background: #737373;
  border-radius: 5px;
  margin-top: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.next-button {
  border: none;
  outline: none;
  width: 150px;
  height: 45px;
  background: #ff4703;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
.next-button2 {
  border: none;
  outline: none;
  width: 200px;
  height: 50px;
  background: #ff4703;
  border-radius: 5px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.design {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
}
.plus {
  height: 420px;
  background: #0e0e0e;
  border-radius: 20px;
  margin-top: 50px;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 50px;
  padding-right: 50px;
}
.sects {
  background: #000000;
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aifill {
  color: #464852;
  font-size: 50px;
}
.seeULater {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
  color: #ffffff;
}
.upper {
  background: #333333;
  border-bottom: 1px solid rgba(115, 115, 115, 0.5);
  border-radius: 20px 20px 0px 0px;
  height: 55px;
  display: flex;
  align-items: center;
}
.calories {
  display: flex;
  justify-content: center;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}
.times {
  color: white;
  cursor: pointer;
}
.subb {
  margin-top: 30px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.goku {
  height: 60px;
  background: #000000;
  border-radius: 5px;
  color: white;
  padding-left: 20px;
  font-family: 'Manrope';
  margin-top: 20px;
  width: 100%;
  outline: none;
  border: none;
}
.save {
  height: 59px;
  width: 100%;
  background: #ff4703;
  border-radius: 5px;
  outline: none;
  border: none;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
.willo {
  background: #333333 !important;
  border-bottom: 1px solid rgba(115, 115, 115, 0.5) !important;
  border-radius: 20px 20px 0 0 !important;
}
.accordian-name {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}
.expand {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
}
.subbz {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.testxc {
  outline: none;
  height: 60px;
  border: none;
  background: #000000;
  border-radius: 5px;
  color: white;
  width: 100%;
  padding-left: 20px;
}

.lulu {
  background: #000000;
  border-radius: 5px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video_tag {
  /* position: relative; */
  width: 100% !important;
  border-radius: 5px !important;
  height: 400px !important;
  object-fit: cover !important;
}

.nsv {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}
.Delete {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  outline: none;
  border: none;
  width: 135px;
  height: 45px;
  background: #464852;
  border-radius: 5px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
}
.d-mark {
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
}
.yes {
  width: 140px;
  height: 40px;
  outline: none;
  border: none;
  background: #ff4703;
  border-radius: 8px;
  color: #ffffff;
}
.No {
  width: 140px;
  height: 40px;
  outline: none;
  border: none;
  background: #464852;
  border-radius: 8px;
  color: #ffffff;
}
.over-to-center {
  display: flex;
  justify-content: center;
  font-size: 55px;
  color: #ffffff;
  opacity: 0.5;
}
.success_message {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}
.continue {
  width: 100%;
  height: 50px;
  background: #ff4703;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-top: 30px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
.cancle {
  width: 100%;
  height: 50px;
  background-color: rgba(255, 0, 0, 0);
  outline: none;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
.flips {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploaded-image {
  background-color: rgb(233, 233, 233);
  margin-top: 20px;
  height: 300px;
  width: 300px;
  object-fit: cover;
  border-radius: 8px;
}
.uploaded-image-container {
  position: relative;
}

.close-button {
  position: absolute;
  top: 28px;
  right: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  height: 25px;
  width: 25px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.randsom {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.delete_part {
  margin-left: 30px;
  cursor: pointer;
}

.video-player-wrapper {
  width: 100%;
}
