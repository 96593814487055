.nogodey {
  background-color: black;
  padding-top: 55px;
  padding-bottom: 20px;
}
.body {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: var(--grey, #f1f2f5);
  font-family: Outfit;

  margin-bottom: 20px;
  cursor: pointer;
}
.wantes {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 25px;
  color: #ffffff;
}
.inini {
  background: #ffffff;
  border: none;
  outline: none;
  border: 0.8117px solid #d3d4d6;
  border-radius: 4.0585px;
  height: 53.56px;
  width: 100%;
  margin-top: 15px;
  padding-left: 12px;
}
.seb {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #ff4703;
  border-radius: 5px;
  height: 55px;
  width: 178px;
  outline: none;
  border: none;
  margin-top: 15px;
}

.twenty {
  color: var(--white, #fff);
  text-align: center;
  /* body-text 3 */
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 15px; */
}
body {
  background-color: black !important;
}

.mader {
  color: #fff;
  text-align: center;

  /* body-text 2 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
.apolo {
  margin-right: 25px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.swiede {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 768px) {
  .twenty {
    margin-top: 10px !important;
  }
  .swiede {
    display: flex;
    justify-content: center;
    margin-top: 10px !important;
  }
}
